[class^='icon-'],
[class*=' icon-'] {
    font-size: 24px;

    &[class*='-12'] {
        font-size: 12px;
    }

    &[class*='-16'] {
        font-size: 16px;
    }

    &[class*='-20'] {
        font-size: 20px;
    }

    &[class*='-32'] {
        font-size: 32px;
    }

    &[class*='-40'] {
        font-size: 40px;
    }

    &[class*='-48'] {
        font-size: 48px;
    }
}
